import "./NavbarStyles.css";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

// ... (existing imports)

const Navbar = () => {
  const handleContactClick = () => {
    window.location.href = "mailto:lgarg2@illinois.edu";
  };

  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  const handleClick = () => setClick(!click);
  const changeColor = () => {
    if (window.scrollY >= 150) {
      setColor(true);
    } else {
      setColor(false);
    }

    // Update activeLink based on the scroll position
    const homeSection = document.getElementById("home");
    const experienceSection = document.getElementById("experience");
    const projectsSection = document.getElementById("projects");

    const offset = 100; // Adjust this offset as needed

    if (
      window.scrollY >= homeSection.offsetTop - offset &&
      window.scrollY < experienceSection.offsetTop - offset
    ) {
      setActiveLink("home");
    } else if (
      window.scrollY >= experienceSection.offsetTop - offset &&
      window.scrollY < projectsSection.offsetTop - offset
    ) {
      setActiveLink("experience");
    } else if (window.scrollY >= projectsSection.offsetTop - offset) {
      setActiveLink("projects");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToSection = (sectionId) => {
    scroll.scrollTo(sectionId, {
      spy: true,
      smooth: true,
      duration: 500,
    });
    setClick(false);
  };

  const isHomePage = location.pathname === "/";

  return (
    <div className={click ? "headernav header-bg" : "headernav"}>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <ScrollLink
            to="home"
            className={activeLink === "home" ? "active-link" : ""}
            onClick={() => {
              scrollToTop();
              setActiveLink("home");
            }}
          >
            <p>Home</p>
          </ScrollLink>
        </li>
        {isHomePage && (
          <>
            <li>
              <ScrollLink
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
                className={activeLink === "experience" ? "active-link" : ""}
                onClick={() => {
                  scrollToSection("experience");
                  setActiveLink("experience");
                }}
              >
                <p>Experience</p>
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
                className={activeLink === "projects" ? "active-link" : ""}
                onClick={() => {
                  scrollToSection("projects");
                  setActiveLink("projects");
                }}
              >
                <p>Projects</p>
              </ScrollLink>
            </li>
          </>
        )}
      </ul>
      <div>
        <p className="ContactBtn" onClick={handleContactClick}>
          Contact
        </p>
      </div>

      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fefdfd" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fefdfd" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
