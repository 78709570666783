import React from "react";
import Navbar from "../components/Navbar";

import AboutIntro from "../components/AboutIntro";
import Work from "../components/Work";

const Home = () => {
  return (
    <div className="home">
      <Navbar></Navbar>
      <AboutIntro />
      <Work  />
    </div>
  );
};

export default Home;
