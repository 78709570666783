import "./WorkCardStyles.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const WorkCard = (props) => {
  return (
    <div className="project-card">
      <img src={props.imgsrc} alt="image" />
      <h2 className="project-title">{props.title}</h2>

      <div className="pro-btns">
        <p className="tags">{props.attribute1}</p>
        {props.attribute2 && <p className="tags">{props.attribute2}</p>}
        {props.attribute3 && <p className="tags">{props.attribute3}</p>}
      </div>

      <div className="pro-details">
        <p>{props.text}</p>
      </div>

      <div className="pro-btns">
        <Link to={props.view} className="tbtn">
          <p>View</p>
          <FaArrowRight className="arrow" />
        </Link>
        {props.source && (
          <NavLink to={props.source} className="tbtn">
            <p>Source</p>
            <FaArrowRight className="arrow" />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default WorkCard;
