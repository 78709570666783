import gened from "../assets/uiucgen.png";
import olympic from "../assets/olympic.png";
import baby from "../assets/baby.png";
import IlliniTransit from "../assets/IlliniTransit.png";
import DonorSpace from "../assets/DonorSpace.png";
import ScholarSphere from "../assets/ScholarSphere.png";

const ProjectCardData = [
  {
    imgsrc: IlliniTransit,
    title: "Illini Transit",
    text: "This web app finds the fastest bus route for a user within the University of Illinois Urbana-Champaign by enhancing Dijkstra's algorithm to handle multi-edge graphs.",
    view: "https://manifest-stream-425603-e1.uc.r.appspot.com/",
    source: "https://github.com/GargLeisha/MyIlliniTransit",
    attribute1: "Flask",
    attribute2: "Javascript",
  },
  {
    imgsrc: ScholarSphere,
    title: "Scholar Sphere Website",
    text: "This website was designed and developed for my tutoring business to host our product offerings and to serve as a sales channel.",
    view: "https://scholar-sphere.com/",
    attribute1: "React",
    attribute2: "ChakraUI",
    attribute3: "Figma",
  },
  {
    imgsrc: DonorSpace,
    title: "Donor Space",
    text: "Donor Space, designed for the Akama Fund, simplifies the scholarship endowment process by allowing students to browse and apply for scholarships quickly and by offering a form-based scholarship creation process for donors.",
    view: "https://donorspace.akamafund.org/",
    attribute1: "Figma",
  },
  {
    imgsrc: gened,
    title: "UIUC GenEd GPA Filterer",
    text: "This tool helps students compare the GPAs of general education courses at the University of Illinois Urbana - Champaign. ",
    view: "/geneds",
    source: "https://github.com/GargLeisha/SQLprojects/blob/main/UIUCGenEds",
    attribute1: "PostgreSQL",
    attribute2: "Tableau",
  },
  {
    imgsrc: baby,
    title: "Baby Names Visualization",
    text: "With this tool, users can search up their name and see how many babies in the US were given that name from 1880 - 2022.",
    view: "/babynames",
    source: "https://github.com/GargLeisha/SQLprojects/blob/main/BabyNames",
    attribute1: "PostgreSQL",
    attribute2: "Tableau",
  },
  {
    imgsrc: olympic,
    title: "Summer Olympic Rankings",
    text: "This visualization showcases the medals won by each country in the Summer Olympics since 1896 and the cumulative sum. ",
    view: "/olympics",
    source:
      "https://github.com/GargLeisha/SQLprojects/blob/main/SummerOlympics",
    attribute1: "PostgreSQL",
    attribute2: "Tableau",
  },
];

export default ProjectCardData;
