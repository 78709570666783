import React from "react";
import "./AboutIntroStyles.css";
import Leisha from "../assets/me.png";
import Resume from "..//assets/LeishaGargResume.pdf";
import { FaDownload } from "react-icons/fa";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const AboutIntro = () => {
  const resumeUrl = Resume; // Update with the actual path to your resume file
  const handleDownloadClick = () => {
    window.location.href = resumeUrl;
  };
  return (
    <div>
      <div className="whole" id="home"></div>

      <div className="container4">
        <div className="text-container4">
          <h1>Hi, I am Leisha Garg.</h1>
          <p>
            I am a student at the University of Illinois Urbana-Champaign
            majoring in Statistics and Computer Science. I am really interested
            in data analysis and applying my coursework to real problem solving.
          </p>
        </div>
        <div className="image-container4">
          {/* Image on the right */}
          <img src={Leisha} alt="Leisha Garg" />
        </div>
      </div>
      <div className="myexp" id="experience">
        <h1>Experience</h1>
        <p>
          Download Resume{" "}
          <FaDownload
            onClick={handleDownloadClick}
            style={{ cursor: "pointer" }}
          />
        </p>
      </div>
      <div>
        <div className="card-holder">
          <div className="experience-card">
            <p>Jan. 2024 - Present</p>
            <h2>Consultant @ OTCR Consulting</h2>
          </div>
          <div className="experience-card">
            <p>Jan. 2024 - Present</p>
            <h2>Product Designer @ Hack4Impact UIUC </h2>
          </div>
          <div className="experience-card">
            <p>May 2024 - Present</p>
            <h2>Co-Founder @ Scholar Sphere</h2>
          </div>
        </div>
        <div className="myexp">
          <h1 id="projects">Projects</h1>
        </div>
      </div>
    </div>
  );
};

export default AboutIntro;
