import React from "react";

import "./index.css";
import Home from "./routes/Home";
import { Route, Routes } from "react-router-dom";
import TableauViz from "./routes/GenEds";
import TableauViz2 from "./routes/BabyNames";
import TableauViz3 from "./routes/Olympics";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/geneds" element={<TableauViz />} />
      <Route path="/babynames" element={<TableauViz2 />} />
      <Route path="/olympics" element={<TableauViz3 />} />
    </Routes>
  );
}

export default App;
