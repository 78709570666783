import React, { useEffect } from "react";
import "../components/genedstyles.css";

import Navbar2 from "../components/NavBar2";

const TableauViz = () => {
  useEffect(() => {
    const containerDiv = document.getElementById("tableauVizContainer");

    const options = {
      hideTabs: true,
      onFirstInteractive: () => {
        // Optional: Add custom logic when the viz is first interactive
      },
    };

    const vizUrl =
      "https://public.tableau.com/shared/57HTFZ4CK?:display_count=n&:origin=viz_share_link";
    const viz = new window.tableau.Viz(containerDiv, vizUrl, options);

    // Cleanup on component unmount
    return () => viz.dispose();
  }, []); // Empty dependency array ensures the effect runs once after mount

  return (
    <>
      <Navbar2></Navbar2>
      <div className="contentg">
        <div className="contain">
          <h1>UIUC GenEd Visualization</h1>
          <div className="tabedits" id="tableauVizContainer"></div>
        </div>
      </div>
    </>
  );
};

export default TableauViz;
