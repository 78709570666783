import "./NavbarStyles.css";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar2 = () => {
  const handleContactClick = () => {
    // Replace 'your.email@example.com' with your actual email address
    window.location.href = "mailto:lgarg2@illinois.edu";
  };

  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);
  const location = useLocation();

  const handleClick = () => setClick(!click);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  return (
    <div className={click ? "headernav header-bg" : "headernav"}>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <NavLink to="/">
            <p>Home</p>
          </NavLink>
        </li>
        <li>
          <div>
            <p className="ContactBtn2" onClick={handleContactClick}>
              Contact
            </p>
          </div>
        </li>
      </ul>

      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fefdfd" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fefdfd" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar2;
